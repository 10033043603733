body {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(92, 92, 92, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(92, 92, 92, 0.3);
  border-radius: 6px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
  border-radius: 6px;
  background-color: #ababab;
}